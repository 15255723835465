<template>
  <ManagersComponent />
</template>

<script>
import ManagersComponent from '@/components/ManagersComponent.vue'

export default {
  name: 'HomeView',
  components: {
    ManagersComponent
  },
  created () {
    document.title = 'Быстрый доступ к окнам'
  }
}
</script>
