<template>
  <router-view/>
</template>

<style lang="scss">
*, :after, :before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  background-color: rgb(245 245 245)!important;
  text-wrap: balance;
}

body {
  margin: 2em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(245 245 245)!important;
  font-size: calc(20px + 8 * (100vw - 320px) / 880);
  max-width: 550px;
}

h3 {
  margin-bottom: 0.7em;
  text-align: center;
}

a {
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0.5em;
}

@media only screen and (min-width: 1024px) {
  .container {
    font-size: 30px;
  }
}

.info-link {
  color: rgb(116, 168, 155);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0.4em 0;
}

.info-link:active, .info-link:hover {
  color: rgb(220, 20, 60);
}

.manager-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
ul {
  list-style: none;
}

li {
  display: flex;
  align-items: center;
}

.none {
  padding-left: 2em;
  background-position: 0 50%;
  background-size: 1.6em;
  background-repeat: no-repeat;
}

.YaMapsSvg {
  background-image: url(@/assets/YaMapsSvg.svg)
}

.VkSvg {
  background-image: url(@/assets/VkSvg.svg)
}

.TgSvg {
  background-image: url(@/assets/TgSvg.svg)
}

.WaSvg {
  background-image: url(@/assets/WaSvg.svg)
}

.SiteSvg {
  background-image: url(@/assets/SiteSvg.svg)
}

.RehauSvg {
  background-image: url(@/assets/RehauSvg.svg)
}

.CallSvg {
  background-image: url(@/assets/CallSvg.svg)
}
</style>
