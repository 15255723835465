<script>
export default {
  name: 'ManagersComponent',
  props: ['phone'],
  data () {
    return {
      modalIndex: null,
      showModal: false,
      managerName: '',
      hidePhone: '',
      openPhone: '',
      title: '',
      sertName: '',
      type: 'window',
      managers: [
        {
          type: 'window',
          managerName: 'Елена',
          hidePhone: '79995567559',
          openPhone: '7 (999) 556-75-59'
        },
        {
          type: 'window',
          managerName: 'Константин',
          hidePhone: '79995567556',
          openPhone: '7 (999) 556-75-56'
        },
        {
          type: 'window',
          managerName: 'Ольга',
          hidePhone: '79995567561',
          openPhone: '7 (999) 556-75-61'
        },
        {
          type: 'window',
          managerName: 'Юлия',
          hidePhone: '79995567548',
          openPhone: '7 (999) 556-75-48'
        },
        {
          type: 'plumbing',
          managerName: '',
          hidePhone: '',
          openPhone: '',
          sertName: ''
        },
        {
          type: 'director',
          managerName: 'Поляков Сергей Анатольевич',
          hidePhone: '79672633434',
          openPhone: '7 (967) 263-34-34'
        },
        {
          type: 'director',
          managerName: 'Денис',
          hidePhone: '79995437872',
          openPhone: '7 (999) 543-78-72'
        }
      ]
    }
  },
  mounted () {
    for (const item of this.managers) {
      if (item.hidePhone === this.phone) {
        this.type = item.type
        this.managerName = item.managerName
        this.hidePhone = item.hidePhone
        this.openPhone = item.openPhone
        if (item.type === 'window') {
          this.title = `Ваш персональный менеджер: ${this.managerName}`
          document.title = `Ваш персональный менеджер: ${this.managerName}`
          return
        } else if (item.type === 'plumbing') {
          document.title = `Ваш персональный монтажник: ${this.managerName}`
          this.title = `Ваш персональный монтажник: ${this.managerName}`
          this.sertName = item.sertName
          return
        } else if (item.type === 'director') {
          if (this.phone === '79995437872') {
            document.title = `Руководитель направления ИС: ${this.managerName}`
            this.title = `Руководитель направления ИС: ${this.managerName}`
            return
          } else if (this.phone === '79672633434') {
            document.title = `Директор компании Максифорн: ${this.managerName}`
            this.title = `Директор компании Максифорн: ${this.managerName}`
            return
          }
        }
      }
    }
  },
  computed: {
    modalImagePath () {
      return this.modalIndex !== null ? require(`@/assets/${this.sertName}.png`) : ''
    }
  },
  methods: {
    openModal (index) {
      this.modalIndex = index
      this.showModal = true
      document.querySelector('html').style.overflow = 'hidden'
    },
    closeModal () {
      if (event.target.classList.contains('modal') || event.target.classList.contains('modal-image')) {
        this.showModal = false
        this.modalIndex = null
        document.querySelector('html').style.overflow = ''
      }
    }
  }
}
</script>

<template>
  <h3 v-if="managerName">
    {{ title }}
  </h3>
  <h3 v-else>
    Полезные ссылки
  </h3>
  <div class="container">
    <ul>
      <li class="none">
        <div v-if="type === 'plumbing'" class="img" @click="openModal()">
          <img class="img" :src="require(`@/assets/${sertName}.png`)" alt="">
        </div>
      </li>
      <li class="none RehauSvg">
        <a href="https://bit.ly/3aNchpO" rel="nofollow" target="_blank" class="info-link">
          Сертификат партнера
        </a>
      </li>
      <li class="none SiteSvg">
        <a href="https://okno-rf.ru/?utm_source=okno-rf-info" target="_blank" class="info-link">
          Официальный сайт
        </a>
      </li>
      <li class="none YaMapsSvg">
        <a href="https://yandex.ru/maps/org/maksiforn_ofitsialny_diler_rehau/11631901777" target="_blank" class="info-link">
          Мы на Яндекс Картах
        </a>
      </li>
      <li v-if="managerName" class="none TgSvg">
        <a :href="`https://t.me/+${hidePhone}`" rel="nofollow" target="_blank" class="info-link">
          Написать в Telegram
        </a>
      </li>
      <li v-if="managerName" class="none WaSvg">
        <a :href="`https://wa.me/?phone=${hidePhone}`" rel="nofollow" target="_blank" class="info-link">
          Написать в WhatsApp
        </a>
      </li>
      <li v-if="managerName" class="none CallSvg">
        <a :href="`tel:+${hidePhone}`" rel="nofollow" target="_blank" class="info-link">
          <p v-if="type === 'window'">Позвонить менеджеру</p>
          <p v-else-if="type === 'plumbing'">Позвонить монтажнику</p>
          <p v-else-if="type === 'director'">Позвонить</p>
          <p>+{{ openPhone }}</p>
        </a>
      </li>
      <li class="none CallSvg">
        <a href="tel:+74955321282" rel="nofollow" target="_blank" class="info-link">
          <p>Многоканальный номер</p>
          <p>+7 (495) 532-12-82</p>
        </a>
      </li>
    </ul>
    <div class="modal" :class="{ 'is-active': showModal }" @click="closeModal">
      <div class="modal-content">
        <div class="modal-image-container">
          <img class="modal-image" :src="modalImagePath" alt="Большое фото">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.img {
  width: 250px;
}

.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, .7);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1500;
}
.modal.is-active {
  display: block;
}
.modal-content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-image-container {
  display: inline-block;
  overflow: hidden;
  position: relative;
}
.modal-image {
    border-radius: .5em;
    max-height: 90vh;
    max-width: 90vw;
    -o-object-fit: contain;
    object-fit: contain;
}
</style>
