<template>
  <ManagersComponent :phone="phone" />
</template>

<script>
import ManagersComponent from '@/components/ManagersComponent.vue'

export default {
  name: 'ManagerPage',
  components: {
    ManagersComponent
  },
  props: ['phone']
}
</script>
